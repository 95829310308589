import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useMap from './useMap'
import RoomInfo from './RoomInfo'
import FloorButton from 'components/button/FloorButton'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Tab from 'components/button/Tab'
import Icons from 'components/icons/Icons'
import PlaceDetails from './PlaceDetails'
import MapItinerary from './MapItinerary'
import PlaceSearch, { Status } from 'components/map/PlaceSearch'
import Banner from 'components/banner/Banner'

import api from './mapApi'
import floorsApi from 'intervention/api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as themeStore from 'theme/store'
import * as mapStore from './mapStore'
import * as bannersStore from 'banners/store'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'
import { getNumberFromFloorName } from './utils'
import { sortedFloors } from 'intervention/utils'

import { RouteResult } from './visioweb'
import useI18n from 'i18n/useI18n'
import FocusLock from 'react-focus-lock'
import useResizeObserver from 'use-resize-observer'

export type TagName = undefined | 'itinerary' | 'search' | 'details' | 'searchItinerary'
export type ItineraryPoint = 'start' | 'end'

const REFRESH_DELAY = 60 //secondes

interface Props {
  hash: string
  areaUniqueName?: string | null
  navigation: Navigation
  isModalFromZapfloor?: boolean
  deskAreas?: ZapfloorDeskArea[]
  selectDesk?: (selectedDesk?: ZapfloorDeskArea) => void
  seats?: Seat[]
  uniquePlaceInitial?: string
  myDesk?: string
  parkingMode?: boolean
}

export interface AreaWithStatusColor extends Area {
  statusColor?: string
}

const Map = ({
  hash,
  areaUniqueName,
  navigation,
  isModalFromZapfloor,
  deskAreas,
  selectDesk,
  seats,
  uniquePlaceInitial,
  myDesk,
  parkingMode,
}: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const isAccessible = useReducer(themeStore.store, (s) => s.theme) === 'accessible'
  const banners = useReducer(bannersStore.store, (s) => s.banners)
  const badges = useReducer(userStore.store, (s) => s.badges)

  const [selectedTab, setSelectedTab] = React.useState<TagName>()
  const [usePMR, setUsePMR] = React.useState(false)
  const [selectedItineraryPoint, setSelectedItineraryPoint] = React.useState<ItineraryPoint>()
  const [search, setSearch] = React.useState('')
  const [searchStatus, setSearchStatus] = React.useState<Status>('notStarted')
  const [searchResults, setSearchResults] = React.useState<Area[]>([])
  const [navStep, setNavStep] = React.useState(-1)
  const [itineraryInfo, setItineraryInfo] = React.useState<RouteResult>()
  const [clickedPoiId, setClickedPoiId] = React.useState('')
  const [areas, setAreas] = React.useState<AreaWithStatusColor[]>([])
  const [areasStatus, setAreaStatus] = React.useState<APIStatus[]>([])
  const [buildingsVisible, setBuildingsVisible] = React.useState(false)
  const [floors, setFloors] = React.useState<APILocation[]>([])

  const { ref: mainContainerRef, width: mainContainerWidth = 1, height: mainContainerHeight = 1 } = useResizeObserver<
    HTMLDivElement
  >()

  const [ref, , building, floor, place, buildings, actions, itinerary] = useMap(
    areas,
    areasStatus,
    isAccessible,
    hash,
    i18n.lang,
    isModalFromZapfloor,
    deskAreas,
    setClickedPoiId,
    isModalFromZapfloor ? mainContainerWidth : undefined,
    isModalFromZapfloor ? mainContainerHeight : undefined,
    seats?.map((a) => ({ ...a, uniquePlaceName: a.uniqueName })),
    uniquePlaceInitial,
    myDesk,
    parkingMode || false
  )

  const [, currentBreakpoint] = useBreakpoint()

  const tabsRef = React.useRef<HTMLDivElement>(null)

  const currentBuilding = React.useMemo(() => buildings.find((b) => b.id === building), [building, buildings])
  const currentFloor = React.useMemo(() => currentBuilding && currentBuilding.floors.find((f) => f.id === floor), [
    currentBuilding,
    floor,
  ])

  const allFloors = React.useMemo(() => {
    if (currentBuilding) {
      return currentBuilding.floors
        .map((f) => ({ id: f.id, floorNum: getNumberFromFloorName(f.name) }))
        .sort((a, b) => b.floorNum - a.floorNum)
    }
  }, [currentBuilding])

  const allFloorsRefs = React.useMemo(
    () =>
      allFloors &&
      allFloors.reduce((acc, value) => {
        acc[value.id] = React.createRef()
        return acc
      }, {} as { [key: string]: any }),
    [allFloors]
  )

  const navSteps = React.useMemo(() => (itinerary.nav ? itinerary.nav.getNbInstructions() : 0), [itinerary.nav])
  const navInstructions = React.useMemo(
    () =>
      !!itinerary.nav ? Array.from(new Array(navSteps)).map((_, idx) => itinerary.nav?.getInstructionData(idx)) : [],
    [itinerary.nav, navSteps]
  )

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'CARTOGRAPHY'),
    [banners]
  )

  const badgesWithFloors = React.useMemo(
    () =>
      badges
        .filter((badge) => badge.siteId === site?.id && !!badge.floor)
        .sort((a, b) => {
          if (a.isPrincipal) {
            return -1
          }
          if (b.isPrincipal) {
            return 1
          }
          return 0
        }),
    [badges, site]
  )

  const badgesWithBuilding = React.useMemo(
    () =>
      badges
        .filter((badge) => badge.siteId === site?.id && !!badge.building)
        .sort((a, b) => {
          if (a.isPrincipal) {
            return -1
          }
          if (b.isPrincipal) {
            return 1
          }
          return 0
        }),
    [badges, site]
  )

  const hasMultipleBuildings = React.useMemo(() => Array.from(new Set(floors.map((f) => f.buildingId))).length > 1, [
    floors,
  ])

  const buildingsList = React.useMemo(() => {
    return floors.reduce((acc, cur) => {
      if (!acc.some((item) => item.buildingName === cur.buildingName)) {
        acc.push(cur)
      }
      return acc
    }, [] as APILocation[])
  }, [floors])

  const currentBuildingInfos = React.useMemo(() => buildingsList.find((b) => b.visioglobeBuildingId === building), [
    building,
    buildingsList,
  ])

  const defaultFloor = React.useMemo(
    () =>
      badgesWithFloors.length > 0 && !areaUniqueName
        ? floors.find((floor) => floor.myPortalFloorName === badgesWithFloors[0].floor)
        : undefined,
    [badgesWithFloors, floors]
  )

  const defaultBuilding = React.useMemo(
    () =>
      hasMultipleBuildings && badgesWithBuilding.length > 0 && !areaUniqueName
        ? floors.find((floor) => floor.buildingName === badgesWithFloors[0].building)
        : undefined,
    [badgesWithBuilding, floors, hasMultipleBuildings]
  )

  const currentNavStep = navInstructions[navStep]

  React.useEffect(() => {
    if (!!site && !!user) {
      api
        .getAllAreas(site.id, user.type)
        .then((res) => setAreas(res.areas))
        .catch(Logger.error)

      floorsApi
        .getAllFloorLocations(site.id, user.type)
        .then((res) => setFloors(sortedFloors(res.locations)))
        .catch(Logger.error)
    }
  }, [])

  React.useEffect(() => {
    if (!!defaultFloor && !!defaultBuilding && !!defaultBuilding.visioglobeBuildingId && !isModalFromZapfloor) {
      setTimeout(() => actions.goTo(defaultBuilding.visioglobeBuildingId!, defaultFloor.visioglobeFloorId), 500)
    } else if (!!defaultFloor && !!building && !isModalFromZapfloor) {
      setTimeout(() => actions.goTo(building, defaultFloor.visioglobeFloorId), 500)
    }
  }, [defaultFloor, defaultBuilding, building])

  React.useEffect(() => {
    if (currentFloor && allFloorsRefs) {
      const floorRef = allFloorsRefs[currentFloor.id]
      if (floorRef && floorRef.current) {
        floorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }
  }, [currentFloor])

  // Ouvrir automatiquement l'onglet quand l'utilisateur click sur une salle
  React.useEffect(() => {
    if (!!place) {
      setSelectedTab('details')
    }
  }, [place])

  // Déselectionner la salle automatiquement quand l'utilisateur change d"onglet
  React.useEffect(() => {
    if (selectedTab !== 'details') {
      actions.setPlace(undefined)
    }
    if (!selectedTab) {
      closeTabScreen()
    }
  }, [selectedTab])

  React.useEffect(() => {
    if (!!clickedPoiId && !!deskAreas) {
      const selectedDesk = deskAreas.find((d) => d.area.uniquePlaceName === clickedPoiId)
      if (!!selectDesk) {
        selectDesk(selectedDesk)
      }
    }
  }, [clickedPoiId])

  // For navigation

  React.useEffect(() => {
    if (itinerary.nav) {
      setNavStep(0)
    } else {
      setNavStep(-1)
    }
  }, [itinerary.nav])

  // On fetch les pois à interval régulier
  React.useEffect(() => {
    if (areas.length > 0) {
      updatePoisStatus()
      if (!isModalFromZapfloor) {
        const timeout = setInterval(() => {
          updatePoisStatus()
        }, REFRESH_DELAY * 1000)

        return () => clearInterval(timeout)
      }
    }
  }, [areas.length])

  // On zoom sur une salle si elle est sélectionnée depuis la réservation de salle
  React.useEffect(() => {
    if (!!areaUniqueName) {
      if (site && user) {
        api
          .fetchAreaReferentielWithUniqueName(site.id, areaUniqueName, user.type)
          .then((res) => {
            if (res.areas[0]) {
              // La carte a besoin d'un moment pour s'initialiser
              setTimeout(() => actions.setPlace(res.areas[0]), 500)
            }
          })
          .catch(Logger.error)
      } else {
        // Cas de figure pour les restaurants totem : pas de user connecté
        // La carte a besoin d'un moment pour s'initialiser
        setTimeout(() => actions.zoomTo({ place: areaUniqueName }), 4000)
      }
    }
  }, [site, user])

  const updatePoisStatus = () => {
    if (site && user) {
      api
        .getAllStatus(site.id, user.type)
        .then((res) => {
          setAreaStatus(res.status)
          actions.updatePOIs(res.status, isAccessible, isVisitor)
        })
        .catch(Logger.error)
    }
  }

  const navNext = () => {
    if (itinerary.nav && navStep < navSteps - 1) {
      itinerary.nextRoute()
      setNavStep(navStep + 1)
    }
  }

  const navPrevious = () => {
    if (itinerary.nav && navStep > 0) {
      itinerary.previousRoute()
      setNavStep(navStep - 1)
    }
  }

  // is user a visitor or engie employee
  const isVisitor =
    mySites && site && user ? !mySites.find(({ id }) => id === site.id) || user.type === 'EXTERNAL' : true

  const unselectPlace = (tag: TagName) => {
    setSelectedTab(tag)
    actions.setPlace(undefined)
  }

  const closeTabScreen = () => unselectPlace(undefined)

  const computeItinerary = () => {
    if (itinerary.from && itinerary.to) {
      mapStore.actions.setItineraryStatus('loading')
      return itinerary.computeRoute(itinerary.from.uniquePlaceName, itinerary.to.uniquePlaceName, usePMR, i18n.lang)
    }

    return Promise.resolve({} as RouteResult)
  }

  const stopItinerary = () => {
    mapStore.actions.setItineraryStatus('notStarted')
    itinerary.stopRoute()
  }

  const searchPlaces = (search: string) => {
    if (search.length > 2) {
      setSearchStatus('loading')
      if (site && user) {
        api
          .getAllSearchedPlaces(search, site.id, user.type)
          .then((res) => {
            setSearchResults(res.areas)
            setSearchStatus('ready')
          })
          .catch((err) => {
            Logger.error(err)
            setSearchStatus('error')
          })
      }
    }
  }

  // Pour déterminer que faire lorsque l'utilisateur sélectionne une salle dans l'onglet de recherche -> Détails de la salle ou sélectionner comme point de départ/arrivé pour l'itinéraire
  const searchComponentFunction = (area: Area) => {
    if (selectedTab === 'search' && currentBuilding) {
      actions.setPlace(area)
      setSelectedTab('details')
    } else if (selectedTab === 'searchItinerary' && selectedItineraryPoint && site && user) {
      if (selectedItineraryPoint === 'start') {
        itinerary.setFrom(area)
      } else {
        itinerary.setTo(area)
      }
      setSelectedTab('itinerary')
    }
  }

  const renderFloorButton = (f: { id: string; floorNum: number }) => (
    <FloorButton
      ref={allFloorsRefs ? allFloorsRefs[f.id] : undefined}
      key={f.id}
      floorNum={f.floorNum}
      selected={currentFloor?.id === f.id}
      onClick={() => {
        setSelectedTab(undefined)
        !!currentBuilding && actions.goTo(currentBuilding.id, f.id)
      }}
    />
  )

  // Render la barre de recherche pour le responsive en mobile
  const renderMobileSearchBar = () => (
    <SearchContainer visible={!selectedTab || selectedTab === 'search' || selectedTab === 'searchItinerary'}>
      <SearchBarContainer>
        <SearchBar
          onClick={() => {
            if (!selectedTab) {
              setSelectedTab('search')
            }
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={i18n.t('screens.cartography.search')}
          autoFocus
        />
        {search.length > 0 && (
          <SearchCrossContainer
            onClick={() => {
              setSearch('')
              setSearchStatus('notStarted')
            }}>
            <Icons name="cross" size={17} color={Theme.colors.blue} />
          </SearchCrossContainer>
        )}
        <IconContainer>
          <Icons name="search" size={15} color={Theme.colors.blue} />
        </IconContainer>
      </SearchBarContainer>
      <ItineraryIconContainer onClick={() => setSelectedTab('itinerary')} crossVisible={selectedTab === 'search'}>
        <Icons name="itinerary" size={30} color={Theme.colors.blue} />
      </ItineraryIconContainer>
      {selectedTab === 'search' && (
        <SearchBarCrossContainer onClick={() => setSelectedTab(undefined)}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </SearchBarCrossContainer>
      )}
    </SearchContainer>
  )

  const renderRoomInfo = (room: Area, i: number) => (
    <RoomInfo
      key={room.id}
      referentiel={room}
      onSet={searchComponentFunction}
      isLast={searchResults.length === i + 1}
    />
  )

  return (
    <MainContainer isModalFromZapfloor={isModalFromZapfloor} ref={mainContainerRef}>
      <TitleHelmet title={i18n.t('screens.cartography.title')} />
      <MapContainer ref={ref} tabIndex={-1} />
      {!isModalFromZapfloor && (
        <BreadcrumbContainer>
          <Breadcrumb screen="cartography" path="map" whiteBackground navigation={navigation} />
        </BreadcrumbContainer>
      )}

      {!!showedBanner && (
        <BannerContainer>
          <Banner banner={showedBanner} />
        </BannerContainer>
      )}

      {!isModalFromZapfloor && (
        <TabGroupContainer selected={!!selectedTab} ref={tabsRef}>
          <TabContainer selected={selectedTab === 'search' || selectedTab === 'details'}>
            <Tab
              iconName="search"
              onClick={() => setSelectedTab('search')}
              status={
                !selectedTab
                  ? 'neutral'
                  : selectedTab === 'search' || selectedTab === 'details'
                  ? 'selected'
                  : 'unselected'
              }
              ariaLabel={i18n.t('label.ariaLabel.map.searchTab')}
            />
            <OnHoverTitleContainer>
              <OnHoverTitle>{i18n.t('screens.cartography.searchRoom')}</OnHoverTitle>
            </OnHoverTitleContainer>
          </TabContainer>

          <TabContainer selected={selectedTab === 'itinerary' || selectedTab === 'searchItinerary'}>
            <Tab
              iconName="itinerary"
              tabPos="bottom"
              onClick={() => setSelectedTab('itinerary')}
              status={
                !selectedTab
                  ? 'neutral'
                  : selectedTab === 'itinerary' || selectedTab === 'searchItinerary'
                  ? 'selected'
                  : 'unselected'
              }
              ariaLabel={i18n.t('label.ariaLabel.map.itineraryTab')}
            />

            <OnHoverTitleContainer>
              <OnHoverTitle>{i18n.t('screens.cartography.itinerary')}</OnHoverTitle>
            </OnHoverTitleContainer>
          </TabContainer>
        </TabGroupContainer>
      )}

      {currentBreakpoint === 'phone' && renderMobileSearchBar()}

      {allFloors && !isModalFromZapfloor && (
        <FloorsScrollView>
          <FloorsButtonContainer>{allFloors.map(renderFloorButton)}</FloorsButtonContainer>
        </FloorsScrollView>
      )}
      {!!currentBuildingInfos && !isModalFromZapfloor && hasMultipleBuildings && (
        <BuildingContainer onClick={() => setBuildingsVisible(!buildingsVisible)}>
          <BuildingSelected>{currentBuildingInfos.buildingName}</BuildingSelected>
          <Icons name={buildingsVisible ? 'chevron_bottom' : 'chevron_top'} size={16} color={Theme.colors.black} />
        </BuildingContainer>
      )}
      {!isModalFromZapfloor && buildingsVisible && hasMultipleBuildings && (
        <BuildingsItemsContainer>
          {buildingsList.map((b) => (
            <BuildingItemContainer
              key={b.buildingId}
              onClick={() => {
                setBuildingsVisible(false)
                if (!!b.visioglobeBuildingId) {
                  actions.goTo(b.visioglobeBuildingId)
                }
              }}>
              <BuildingSelected>{b.buildingName}</BuildingSelected>
              {b.visioglobeBuildingId === building && <Icons name="check" size={16} color={Theme.colors.blue} />}
            </BuildingItemContainer>
          ))}
        </BuildingsItemsContainer>
      )}
      {!isModalFromZapfloor && (
        <InfoContainer selected={!!selectedTab}>
          <TabContent disabled={!selectedTab || currentBreakpoint === 'phone'} autoFocus={false} shards={[tabsRef]}>
            <CrossContainer>
              <div>
                {selectedTab === 'details' && (
                  <BackIconContainer
                    onClick={() => unselectPlace('search')}
                    aria-label={i18n.t('label.ariaLabel.map.backIcon')}>
                    <Icons name="chevron_left" size={20} color={Theme.colors.blue} />
                  </BackIconContainer>
                )}
              </div>

              <CrossIconContainer
                onClick={closeTabScreen}
                tabIndex={!!selectedTab ? 0 : -1}
                aria-label={i18n.t('label.ariaLabel.tabCrossIcon')}>
                <Icons name="cross" size={30} color={Theme.colors.blue} />
              </CrossIconContainer>
            </CrossContainer>

            {selectedTab === 'search' || selectedTab === 'searchItinerary' ? (
              <PlaceSearch
                listElement={renderRoomInfo}
                resultList={searchResults}
                setResultList={setSearchResults}
                searchText={search}
                setSearchText={setSearch}
                researchStatus={searchStatus}
                setResearchStatus={setSearchStatus}
                searchPlaces={searchPlaces}
                height="calc(100vh - 198px)"
              />
            ) : selectedTab === 'details' && !!place ? (
              <PlaceDetails
                poi={place}
                setSelectedTab={setSelectedTab}
                itinerary={itinerary}
                isVisitor={isVisitor}
                navigation={navigation}
              />
            ) : (
              selectedTab === 'itinerary' && (
                <MapItinerary
                  setSelectedPoint={setSelectedItineraryPoint}
                  computeItinerary={computeItinerary}
                  stopItinerary={stopItinerary}
                  usePMR={usePMR}
                  setUsePMR={setUsePMR}
                  setSelectedTab={setSelectedTab}
                  currentNavStep={currentNavStep}
                  navStep={navStep}
                  navSteps={navSteps}
                  navPrevious={navPrevious}
                  navNext={navNext}
                  itineraryInfo={itineraryInfo}
                  setItineraryInfo={setItineraryInfo}
                  itinerary={itinerary}
                />
              )
            )}
          </TabContent>
        </InfoContainer>
      )}
    </MainContainer>
  )
}

export default Map

// CONTAINERS

const MainContainer = styled('div')<{ isModalFromZapfloor?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  ${(props) => props.isModalFromZapfloor && `height: 100%;`};
  ${(props) => props.isModalFromZapfloor && `width: 100%;`};

  ${(props) => !props.isModalFromZapfloor && `position : absolute`};
  ${(props) => !props.isModalFromZapfloor && `height : calc(100vh - 60px)`};
  ${(props) => !props.isModalFromZapfloor && `width : calc(100vw - 114px)`};
  ${(props) => !props.isModalFromZapfloor && `left : 0px`};
  ${(props) => !props.isModalFromZapfloor && `top : 0px`};

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    ${(props) => !props.isModalFromZapfloor && `width : 100%`};
  }
`
const MapContainer = styled('div')`
  display: flex;
`
const BreadcrumbContainer = styled('div')`
  position: absolute;
  top: 10px;
  left: 10px;
`
const BannerContainer = styled('div')`
  position: absolute;
  top: 80px;
  left: 150px;
  width: calc(100vw - 50%);
  z-index: 10;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100vw - 35%);
  }
`
const FloorsButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  padding-bottom: 10px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 80px;
  }
`

const FloorsScrollView = styled.div`
  position: absolute;
  bottom: 80px;
  left: 30px;
  max-height: calc(100vh - 240px);
  overflow-y: auto;
  overflow-x: hidden;
`

const OnHoverTitleContainer = styled('div')`
  position: absolute;
  z-index: 30;
  right: 70px;
  top: 15px;
  display: none;
  flex-direction: row;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
`
const OnHoverTitle = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  white-space: nowrap;
`
const TabGroupContainer = styled('div')<{ selected: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: flex-end;
  top: 10px;
  right: ${(props) => (props.selected ? 370 : 0)}px;
  transition: right 0.3s;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const TabContainer = styled('div')<{ selected: boolean }>`
  position: relative;
  &:hover ${OnHoverTitleContainer} {
    ${(props) => !props.selected && 'display: flex'};
  }
`
const InfoContainer = styled('div')<{ selected: boolean }>`
  position: absolute;
  top: 0;
  z-index: 2;
  right: ${(props) => (props.selected ? 0 : '-370px')};
  transition: right 0.3s;
  height: calc(100vh - 60px);
  width: 370px;
  background-color: ${(props) => props.theme.colors.white};

  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    position: fixed;
    right: auto;
    top: ${(props) => (props.selected ? '60px' : '100%')};
    height: 100%;
    transition: top 0.3s;
    width: 100%;
  }
`
const CrossContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  margin: 0px 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const BackIconContainer = styled('button')`
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const CrossIconContainer = styled('button')`
  cursor: pointer;
  background: transparent;
  border: 0px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const TabContent = styled(FocusLock)`
  display: flex;
  height: 100%;
  flex-direction: column;
`
const SearchContainer = styled('div')<{ visible: boolean }>`
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.lightGrey};
  top: ${(props) => (props.visible ? 0 : '-58px')};
  transition: 0.2s;
`
const SearchBarContainer = styled('div')`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.white};
  width: 330px;
  height: 38px;
  border-radius: 52px;
  border: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  margin-left: 20px;
`
const SearchCrossContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  padding: 12px;
`
const ItineraryIconContainer = styled('div')<{ crossVisible: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0px 13px 0px 7px;
  ${(props) => props.crossVisible && 'margin-right: 5px;'}
`
const SearchBarCrossContainer = styled('button')`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-right: 13px;
`

// AUTRES

const SearchBar = styled('input')`
  flex: 1;
  ${(props) => props.theme.fonts.label}
  margin-left: 24px;
  border: 0;
  outline: 0;
`

const BuildingContainer = styled('div')`
  position: absolute;
  bottom: 10px;
  left: 30px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  padding: 0 16px;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const BuildingSelected = styled('p')`
  ${(props) => props.theme.fonts.contentTitleBold};
  margin: 0;
  font-weight: 400;
  padding-right: 14px;
`

const BuildingItemContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 8px 16px;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.mediumLightGrey};
  }
`

const BuildingsItemsContainer = styled('div')`
  position: absolute;
  bottom: 80px;
  left: 30px;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 4px;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.white};
`
