import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { imageMimeTypes, documentMimeTypesIntervention } from '../utils'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import api from '../api'

import { FormTextInput, FormValuePicker } from 'components/form/InterventionFormItems'
import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import ToastAlert from 'components/toast/ToastAlert'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'

import { useFormik } from 'formik'

interface Props {
  incident: Incident
  onClaimSubmitted: (claim: Complaint) => void
}
interface Attachment {
  file: File
  format: AttachmentImageFormat | AttachmentDocumentFormat
  type: 'IMAGE' | 'DOCUMENT'
  name: string
  data: string
}

interface Values {
  incidentId: string
  description: string
  attachments: Attachment[]
}

const ClaimForm = ({ incident, onClaimSubmitted }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const hiddenFileInput = React.useRef<HTMLInputElement>(null)

  const onSubmit = (values: Values) => {
    if (!!user && !!currentSite) {
      setIsSubmitting(true)

      const formData = new FormData()
      formData.append('incidentId', values.incidentId)
      formData.append('description', values.description)
      if (values.attachments) {
        formData.append('attachmentsName', values.attachments.map((a) => a.name).toString())
        formData.append('attachmentsContent', values.attachments.map((a) => a.data.split(',').pop() || '').toString())
      }

      api
        .createInterventionClaim(formData, currentSite.id, user.type)
        .then(onClaimSubmitted)
        .catch((err) => {
          Logger.error(err)
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.incident.claim.postError')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .finally(() => setIsSubmitting(false))
    }
  }

  const { values, handleChange, handleSubmit, touched, errors, setFieldValue } = useFormik<Values>({
    initialValues: { incidentId: incident.id, description: '', attachments: [] },
    validateOnChange: true,
    onSubmit,
  })

  const isFormValid = !!values.description

  const onUploadFile = (uploadEvent: React.ChangeEvent<HTMLInputElement>) => {
    if (uploadEvent.target.files && uploadEvent.target.files[0]) {
      const file = uploadEvent.target.files[0]
      const reader = new FileReader()

      reader.readAsDataURL(file)
      reader.onload = (readEvent) => {
        if (readEvent.target) {
          const imageFormat = Object.keys(imageMimeTypes).find(
            (key) => imageMimeTypes[key as AttachmentImageFormat] === file.type
          )
          const documentFormat = Object.keys(documentMimeTypesIntervention).find(
            (key) => documentMimeTypesIntervention[key as AttachmentDocumentFormatIntervention] === file.type
          )
          if (imageFormat || documentFormat) {
            const format = (imageFormat || documentFormat) as
              | AttachmentImageFormat
              | AttachmentDocumentFormatIntervention

            const attachment: Attachment = {
              file: file,
              name: file.name,
              data: readEvent.target.result as string,
              format,
              type: !!imageFormat ? 'IMAGE' : 'DOCUMENT',
            }
            const fileSizeMo = file.size / (1024 * 1024)
            if (fileSizeMo > 10) {
              Modal.open(
                () => (
                  <ValidationContent
                    title={i18n.t('common.error')}
                    description={i18n.t('screens.incident.form.maxSize')}
                    onConfirm={Modal.close}
                    confirmButtonTitle={i18n.t('common.ok')}
                  />
                ),
                false,
                100
              )
            } else {
              setFieldValue('attachments', [attachment])
            }
          }
        }
      }
    }
  }

  return (
    <ModalContainer>
      <TopContainer>
        <ModalTitle>{i18n.t('screens.incident.claim.title')}</ModalTitle>
        <IconContainer
          onClick={ToastAlert.close}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              ToastAlert.close()
            }
          }}>
          <Icons name="cross" color={theme.colors.darkGrey} size={25} />
        </IconContainer>
      </TopContainer>
      <FormContainer>
        <FormValuePicker
          id="InterventionCategoryPicker"
          title={i18n.t('screens.incident.form.requestType')}
          value={i18n.t('screens.incident.claim.claimRequestType', { requestNumber: incident.incidentNumber })}
          ariaLabel={i18n.t('label.ariaLabel.incident.categoryPicker')}
          mandatory
        />

        <FormTextInput
          id="interventionDescription"
          name="description"
          onChange={handleChange}
          title={i18n.t('screens.incident.form.requestDescription')}
          value={values.description}
          error={touched.description ? errors.description : undefined}
          mandatory
          placeholder={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
          titleAriaLabel={i18n.t('screens.incident.form.placeholder.descriptionPlaceholder')}
        />

        <LabelAndInputContainer>
          <PhotoLabelContainer>
            <InputLabel>{i18n.t('screens.incident.form.reportAttachment')}</InputLabel>
          </PhotoLabelContainer>
          {values.attachments.map((a, index) => (
            <>
              <PictureContainer>
                <DeleteIcon
                  onClick={() =>
                    setFieldValue(
                      'attachments',
                      values.attachments.filter((a, idx) => idx !== index)
                    )
                  }>
                  <Icons name="cross" size={20} />
                </DeleteIcon>
                {a.type === 'IMAGE' ? <IncidentPicture src={a.data} /> : <Icons name="news" size={50} />}
              </PictureContainer>
              <AttachmentLabel>{a.name}</AttachmentLabel>
            </>
          ))}
          {values.attachments.length === 0 && (
            <AddPictureButton
              onKeyPress={(e) => {
                if (e.key === 'Enter' && !!hiddenFileInput.current) {
                  hiddenFileInput.current.click()
                }
              }}
              tabIndex={0}
              aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
              <Icons name="plus" size={30} />
              <AddPictureInput
                ref={hiddenFileInput}
                type="file"
                accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypesIntervention)].join(',')}
                onChange={onUploadFile}
              />
            </AddPictureButton>
          )}
        </LabelAndInputContainer>
        <ButtonContainer>
          <Button
            type="submit"
            label={i18n.t('screens.incident.submitDemand')}
            verticalPadding={14}
            font={theme.fonts.h3Bold}
            onClick={handleSubmit}
            disabled={!isFormValid || isSubmitting}
            loading={isSubmitting}
          />
        </ButtonContainer>
      </FormContainer>
    </ModalContainer>
  )
}

export default ClaimForm

const ModalContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  max-height: 80vh;
  max-width: 444px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    max-width: 90vw;
    padding: 20px;
  }
`
const ModalTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  line-height: 30px;
  margin: 0px;
`
const TopContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`
const FormContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const IconContainer = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const LabelAndInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 350px;
  max-width: 450px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`
const PhotoLabelContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 10px 0px;
`

const PictureContainer = styled('div')`
  display: flex;
  position: relative;
  width: fit-content;
`
const DeleteIcon = styled('div')`
  display: flex;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 25px;
  width: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  margin: 20px 0px;
`

// TEXTES

const InputLabel = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`
const AddPictureInput = styled('input')`
  display: none;
`

const AttachmentLabel = styled('label')`
  width: 100%;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  padding: 10px 0px;
  text-overflow: ellipsis;
`

// AUTRES

const AddPictureButton = styled('label')`
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.theme.colors.blue10p};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const IncidentPicture = styled('img')`
  max-width: 100%;
  object-fit: cover;
`
