import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import { zonedTimeToUtc } from 'date-fns-tz'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import useNavigation from 'utils/navigation/useNavigation'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { isBefore } from 'date-fns'

import graphApi from './api/graphRoomApi'
import { MyBooking, MeetingGraph, MyBookingsByDate } from './types'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import ReservationAttendees from './modal/ReservationAttendees'

import Loader from 'react-loader-spinner'
import Image from 'components/image/assets/empty-list.png'
import { findLevelWording } from 'intervention/utils'

interface Props {
  allRooms: Area[]
  refreshToggle: boolean
}

type Status = 'ok' | 'loading' | 'error'

const MyBookingsTab = ({ allRooms, refreshToggle }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()

  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)

  const [myBookings, setMyBookings] = React.useState<MyBooking[]>([])
  const [myGraphBooking, setMyGraphBookings] = React.useState<MeetingGraph[]>()
  const [status, setStatus] = React.useState<Status>('ok')

  const bookingsByDate = React.useMemo(() => {
    if (myBookings) {
      return myBookings
        .reduce((acc, cur) => {
          const index = acc.findIndex(
            (r) => r.date === i18n.t('screens.room.details.dayFormat', { date: new Date(cur.from) })
          )
          if (index > -1) {
            acc[index].bookings.push(cur)
          } else {
            acc.push({
              date: i18n.t('screens.room.details.dayFormat', { date: new Date(cur.to) }),
              bookings: [cur],
            })
          }
          return acc
        }, [] as MyBookingsByDate[])
        .sort((a, b) => (isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1))
    }
    return []
  }, [myBookings])

  React.useEffect(() => {
    if (myGraphBooking) {
      setMyBookings(
        myGraphBooking
          .map((b) => {
            const corresponding = allRooms.find(
              (r) =>
                !!b.attendees
                  .map((a) => a.emailAddress.address)
                  .find((address) => address.toLowerCase() === r.office365Id?.toLowerCase())
            )
            const roomAttendee = b.attendees.find(
              (a) =>
                !!allRooms.find(
                  (r) => !!r.office365Id && r.office365Id.toLowerCase() === a.emailAddress.address.toLowerCase()
                )
            )

            return {
              id: b.id,
              floor: corresponding ? corresponding.floor : undefined,
              name: corresponding ? corresponding.name : b.location.displayName,
              from: b.start.dateTime,
              to: b.end.dateTime,
              subject: b.subject,
              attendees: b.attendees,
              uniquePlaceName: corresponding ? corresponding.uniquePlaceName : undefined,
              refused: roomAttendee ? roomAttendee.status?.response === 'declined' : undefined,
              isOnlineMeeting: !!b.isOnlineMeeting,
            } as MyBooking
          })
          .filter((booking) => booking.floor !== undefined)
      )
    }
  }, [myGraphBooking])

  React.useEffect(() => {
    loadMyBookings()
  }, [refreshToggle])

  const loadMyBookings = () => {
    if (site && user) {
      setStatus('loading')
      graphApi
        .monthEvents(user.type, site.timezone)
        .then((res) => {
          setMyGraphBookings(res)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const goToMap = (areaId?: string) => {
    if (site && site.webVisioglobeHash && areaId) {
      navigation.pushQuery(`/map`, `?areaId=${areaId}`, { origin: 'localisation' })
    }
  }

  const openModal = (
    title: string,
    showCancel: boolean,
    onConfirm?: () => void,
    confirmTitle?: string,
    ariaLabelConfirm?: string,
    ariaLabelCancel?: string
  ) =>
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          if (!!onConfirm) {
            onConfirm()
          }
        }}
        cancelButton={showCancel}
        confirmButtonTitle={confirmTitle}
        font={Theme.fonts.label}
      />
    ))

  const confirmCancellation = (id: string) =>
    openModal(
      i18n.t('screens.room.cancelReservation.confirm'),
      true,
      () => {
        cancelReservation(id)
        Modal.close()
      },
      i18n.t('common.confirm'),
      i18n.t('label.ariaLabel.booking.confirmCancellation'),
      i18n.t('label.ariaLabel.booking.cancelCancellationAttempt')
    )

  const cancelReservation = (id: string) => {
    if (user) {
      graphApi
        .delete(user.type, id)
        .then(() => {
          loadMyBookings()
          openModal(
            i18n.t('screens.room.cancelReservation.success'),
            false,
            undefined,
            undefined,
            i18n.t('label.ariaLabel.windowCrossIcon')
          )
        })
        .catch((err) => {
          Logger.error(err)
          openModal(
            i18n.t('screens.room.cancelReservation.failure'),
            true,
            () => cancelReservation(id),
            i18n.t('common.retry'),
            i18n.t('label.ariaLabel.booking.retryCancellation'),
            i18n.t('label.ariaLabel.booking.cancelCancellationAttempt')
          )
        })
    }
  }

  const renderBooking = (b: MyBooking, index: number, list: MyBooking[]) => {
    if (!site) {
      return null
    }
    const isLast = list.length === index + 1

    const participantsList = b.attendees
      .filter(
        (a) =>
          !allRooms.map((r) => r.office365Id).find((r) => r?.toLowerCase() === a.emailAddress.address.toLowerCase())
      )
      .filter((a) => a.emailAddress.address.toLowerCase() !== user?.email?.toLowerCase())

    const openAttendeesModal = () => {
      const roomAttendeeId = b.attendees.find((a) =>
        allRooms.find((r) => r.office365Id?.toLowerCase() === a.emailAddress.address.toLowerCase())
      )

      if (roomAttendeeId) {
        Modal.open(() => (
          <ReservationAttendees
            attendees={participantsList}
            reservationId={b.id}
            refreshBookings={loadMyBookings}
            roomAttendee={roomAttendeeId}
            navigation={navigation}
          />
        ))
      }
    }

    const findRoom = allRooms.find((r) => r.uniquePlaceName === b.uniquePlaceName)
    const start = zonedTimeToUtc(b.from, site.timezone)
    const end = zonedTimeToUtc(b.to, site.timezone)

    return (
      <div key={b.id}>
        <BookingContainer>
          <InfosContainer>
            {!b.refused && (
              <AlignIconText>
                <Icons name="building" size={15} color={Theme.colors.blue} />
                <InfoText>{findLevelWording(b.floor || 0, i18n, findRoom?.floorCustomName)}</InfoText>
              </AlignIconText>
            )}

            <AlignIconText>
              <Icons name="roombooking" size={15} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.roomName')} />
              <InfoText>{b.name}</InfoText>
            </AlignIconText>

            {b.refused && (
              <AlignIconText>
                <Icons name="warning" size={15} color={Theme.colors.red} />
                <WarningText>{i18n.t('screens.room.roomRefused')}</WarningText>
              </AlignIconText>
            )}

            <AlignIconText>
              <Icons name="agenda" size={15} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.time')} />
              <InfoText>{i18n.t('screens.room.timeFromTo', { time1: start, time2: end })}</InfoText>
            </AlignIconText>

            {b.isOnlineMeeting && (
              <AlignIconText>
                <Icons name="teams" size={15} color={Theme.colors.blue} />
                <InfoText>{i18n.t('screens.room.form.teams')}</InfoText>
              </AlignIconText>
            )}

            {b.subject && b.subject !== '' && (
              <AlignIconText>
                <Icons name="pencil" size={15} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.subject')} />
                <InfoText>{b.subject}</InfoText>
              </AlignIconText>
            )}
            <AttendeeFocusContainer>
              <AttendeesText
                onClick={openAttendeesModal}
                aria-label={i18n.t(
                  `label.ariaLabel.room.modifyParticipants${site?.id === '4' ? 'AndResource' : ''}List`
                )}>
                {participantsList.length > 0
                  ? i18n.t(`screens.room.seeNbAttendees${site?.id === '4' ? 'OrResource' : ''}`, {
                      count: participantsList.length,
                    })
                  : i18n.t(`screens.room.noParticipants${site?.id === '4' ? 'OrResource' : ''}Yet`)}
              </AttendeesText>
            </AttendeeFocusContainer>
          </InfosContainer>

          <ButtonsMainContainer>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.room.details.shortcuts.locate')}
                font={Theme.fonts.label}
                verticalPadding={6}
                horizontalPadding={0}
                onClick={() => goToMap(b.uniquePlaceName)}
                ariaLabel={i18n.t('label.ariaLabel.room.locateOnMap')}
              />
            </ButtonContainer>

            <ButtonContainer>
              <Button
                label={i18n.t('common.cancel')}
                font={Theme.fonts.label}
                color={Theme.colors.white}
                textColor={Theme.colors.blue}
                verticalPadding={6}
                horizontalPadding={0}
                shadow
                onClick={() => confirmCancellation(b.id)}
                ariaLabel={i18n.t('label.ariaLabel.booking.cancelBooking')}
              />
            </ButtonContainer>
          </ButtonsMainContainer>
        </BookingContainer>
        {!isLast && <BlueLineBreak />}
      </div>
    )
  }

  if (!site) {
    return null
  }

  return (
    <MainContainer>
      <Title>{i18n.t('screens.room.myBookings')}</Title>
      {status === 'loading' ? (
        <LoaderContainer>
          <Loader type="TailSpin" color={Theme.colors.blue} />
        </LoaderContainer>
      ) : status === 'ok' ? (
        bookingsByDate.length !== 0 ? (
          <ScrollableContainer>
            {bookingsByDate.map((b) => (
              <div key={b.date}>
                <DateTitle>
                  {i18n.t('screens.room.reservationDate', {
                    date: new Date(`${b.date}T00:00:00`),
                  })}
                </DateTitle>
                <BookingsContainer>{b.bookings.map(renderBooking)}</BookingsContainer>
              </div>
            ))}
          </ScrollableContainer>
        ) : (
          <EmptyBookingsContainer>
            <EmptyBookingsText>{i18n.t('screens.room.emptyBookings')}</EmptyBookingsText>
            <EmptyImageplaceHolder src={Image} alt="" />
          </EmptyBookingsContainer>
        )
      ) : (
        <ErrorText>{i18n.t('screens.room.noBookingsError')}</ErrorText>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 60px);
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    max-height: calc(100% - 20px);
    height: 100%;
  }
`
const BookingsContainer = styled('div')`
  margin: 0px 24px;
`
const BookingContainer = styled('div')`
  display: flex;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 15px 0px;
  padding: 5px;
`
const InfosContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const AlignIconText = styled('div')`
  display: flex;
  align-items: center;
`
const ButtonsMainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 71px;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 71px;
  margin: 3.5px 0px;
`
const ScrollableContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const EmptyBookingsContainer = styled('div')`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 43px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    align-items: center;
  }
`
const AttendeeFocusContainer = styled('div')`
  display: flex;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0px 0px 20px 14px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    ${(props) => props.theme.fonts.bodyBold};
    font-size: 24px;
  }
`
const DateTitle = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 0px 6px 0px;
  padding: 6.5px 0px 6.5px 24px;
  text-transform: capitalize;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`
const InfoText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 0px 0px 5px;
`
const WarningText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.red};
  margin: 0px 0px 0px 5px;
`
const AttendeesText = styled('button')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: underline;
  margin: 0px;
  padding: 0px;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`
const EmptyBookingsText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  text-align: center;
  margin: 20px 20px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 50px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  border-radius: 2px;
`
const EmptyImageplaceHolder = styled('img')`
  object-fit: fill;
  width: 375px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100vw - 40px);
  }
`

export default MyBookingsTab
